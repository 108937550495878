$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-footer-algolia-hit {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  margin: -8px;
  min-height: 40px;
  padding: 8px;
  transition: background var(--transition);
  width: calc(100% + 16px); // add removed margin

  &--loading {
    cursor: default;
  }

  @media (min-width: $device-width-s) {
    &:hover:not(&--loading) {
      background: var(--color-grey-50);
    }
  }
}

.header-footer-algolia-hit:not(:last-of-type) {
  margin-bottom: 12px;
}

.header-footer-algolia-hit__icon {
  fill: var(--color-teal-800);
  flex-shrink: 0;
  margin-right: 12px;
  transition: transform .25s ease;

  @media (min-width: $device-width-m) {
    .header-footer-algolia-hit:hover:not(&--loading) & {
      transform: scale(1.2);
    }
  }
}

.header-footer-algolia-hit__image {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  margin-right: 12px;
  overflow: hidden;
  transition: transform .25s ease;
  width: 40px;

  @media (min-width: $device-width-m) {
    .header-footer-algolia-hit:hover:not(&--loading) & {
      transform: scale(1.2);
    }
  }
}

.header-footer-algolia-hit__image-src {
  flex: none;
  height: 100%;
}

.header-footer-algolia-hit__image-src img {
  height: 100%;
  width: auto;
}

.header-footer-algolia-hit__content {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  height: 100%;
  text-align: left;
  width: 100%;
}

.header-footer-algolia-hit__content-name {
  align-items: center;
  display: flex;
}

.header-footer-algolia-hit__content-geographical-item {
  color: var(--color-grey-400);
  display: inline;
}

.header-footer-algolia-hit__content-geographical-item:not(:first-of-type)::before {
  content: ', ';
}

.header-footer-algolia-hit-container:not(.header-footer-algolia-hit-container--faq) {
  margin-bottom: 12px;
}

.header-footer-algolia-result__show-more {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
