$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
$search-field-height: 58px;

.header-search-field {
  align-items: center;
  color: var(--color-grey-600);
  display: flex;
  justify-content: space-between;
  left: calc(100% - 36px);
  position: absolute;
  top: 20px;
  transition: left var(--transition), border var(--transition), border-width var(--transition);

  @media (min-width: $device-width-m) {
    border: var(--color-grey-300) solid 1px;
    border-radius: 20px;
    margin-right: 12px;
    padding: 0 12px;
    position: static;
    transition: border-color var(--transition), box-shadow var(--transition);
    width: auto;

    &:hover {
      border-color: var(--color-grey-400);
    }
  }
}

@media (max-width: $device-width-m-max) {
  .header-search-field--expanded {
    background: --color-white;
    height: $search-field-height;
    left: 0;
    padding-left: 12px;
    padding-right: 12px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 503;
  }
}

@media (min-width: $device-width-m) {
  .header-search-field--focused:hover,
  .header-search-field--focused {
    border-color: var(--color-teal-500);
    box-shadow: 0 0 8px hsla(193, 75%, 48%, .25);
  }
}

.header-search-field__label {
  align-items: center;
  background: transparent;
  border: 0;
  color: var(--color-grey-400);
  font-size: 20px;
  height: 28px;
  min-width: 16px;
  padding: 0;
  padding-top: 1px;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: $device-width-m) {
    color: var(--color-black);
    font-size: 14px;
    order: 2;
    padding-top: 3px;
  }

  &:focus {
    outline: none;
  }

  .header-app--globetrotter & {
    margin-top: -5px;

    @media (min-width: $device-width-m) {
      margin-top: 0;
    }
  }
}

.header-search-field--focused .header-search-field__label {
  margin-top: 0;
}

.header-search-field__cancel-btn {
  display: none;
  padding: 0;

  .header-search-field--expanded & {
    display: inline;

    @media (min-width: $device-width-m) {
      display: none;
    }
  }
}

@media (max-width: $device-width-m-max) {
  .header-search-field__mobile-overlay {
    background-color: rgba(0, 0, 0, .5);
    height: 100%;
    left: 0;
    position: fixed;
    top: $search-field-height;
    width: 100%;
    z-index: 502;
  }
}

// no scroll when search-field is expanded for mobile
@media (max-width: $device-width-m-max) {
  body.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

// Overrides for webui styling for autosuggest
.header-search-field__autosuggest {
  flex-grow: 15;
}

.header-search-field__autosuggest .webui-autosuggest__container {
  position: relative;
}

.header-search-field__autosuggest .webui-autosuggest__input {
  border: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  transition: width var(--transition);
  width: 0;

  @media (min-width: $device-width-m) {
    font-size: 14px;
    padding: 4px 4px 4px 8px;
    width: 128px;
  }

  .header-search-field--expanded & {
    padding: 4px 30px 4px 8px;
    width: 100%;

    @media (min-width: $device-width-m) {
      padding-right: 0;
      width: 128px;
    }
  }
}

.header-search-field__autosuggest .webui-autosuggest__suggestions-container {
  left: 0;
  position: fixed;
  right: 0;
  top: 57px;
  width: 100vw;
  z-index: 503;

  @media (min-width: $device-width-m) {
    left: -13px;
    position: absolute;
    top: 28px;
    width: calc(100% + 42px);
  }
}

.header-search-field__autosuggest .webui-autosuggest__delete-icon {
  display: none;
  padding: 0;

  .header-search-field--expanded & {
    display: block;
    top: 7px;

    @media (min-width: $device-width-m) {
      display: none;
    }
  }
}

@media (max-width: $device-width-m-max) {
  .header-search-field-algolia {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.header-search-field-algolia--open {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 503;
}

.header-search-field-algolia--open .header-search-field-algolia__container {
  background-color: var(--color-white);
  width: 100vw;

  @media (max-width: $device-width-m-max) {
    height: 100vh;
  }
}

.header-search-field-algolia__container-search-bar {
  margin: auto;
  max-width: 1280px;

  @media (min-width: $device-width-m) {
    padding: 0 0 0 12px;

    .header-search-field-algolia--open & {
      padding: 14px 0 0;
    }
  }
}
