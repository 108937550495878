$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-footer-algolia-search-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: $device-width-m-max) {
    .header-footer-algolia-search--open & {
      background-color: var(--color-white);
      border-bottom: var(--color-grey-50) solid 1px;
      padding: 0 12px;
      position: fixed;
      top: 0;
      z-index: 2;
    }
  }

  @media (min-width: $device-width-m) {
    justify-content: space-between;

    .header-footer-algolia-search--open & {
      padding: 0 24px;
    }
  }
}

.header-footer-algolia-search-box__input {
  align-items: center;
  color: var(--color-text);
  cursor: text;
  display: flex;
  height: 48px;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: $device-width-m) {
    border: var(--color-grey-400) solid 1px;
    border-radius: 16px;
    height: 32px;
    justify-content: flex-start;
    padding: 0 12px 0 16px;
    transition: border var(--transition), box-shadow var(--transition);
    width: 200px;

    &:not(.header-footer-algolia-search-box__input--focused):hover {
      border-color: var(--color-text);
    }

    .header-footer-algolia-search--open & {
      border-radius: 24px;
      height: 48px;
      margin: 0 auto 20px;
      max-width: 640px;
      padding: 0 24px 0 28px;
      position: relative;
      right: -8px; // half close-icon for center
      top: 20px;
      width: 50%;
    }
  }
}

.header-footer-algolia-search-box__input-icon {
  align-items: center;
  display: inline-flex;
  fill: var(--color-text);
  flex-shrink: 0;
  height: 100%;
  justify-content: flex-start;
  width: 24px;

  @media (max-width: $device-width-m-max) {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  @media (min-width: $device-width-m) {
    .header-footer-algolia-search--open & {
      width: 32px;
    }
  }
}

.header-footer-algolia-search-box__input-field {
  background: transparent;
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::-ms-clear {
    height: 0;
    width: 0;
  }

  @media (max-width: $device-width-m-max) {
    .header-footer-algolia-search:not(.header-footer-algolia-search--open) & {
      display: none;
    }
  }

  @media (min-width: $device-width-m) {
    width: 148px;

    .header-footer-algolia-search--open & {
      font-size: 18px;
      width: 100%;
    }
  }
}

.header-footer-algolia-search-box__cancel {
  font-size: 14px;
}

.header-footer-algolia-search-box__cancel .header-footer-algolia-search-box__cancel-icon { /* remove first class when umbraco fetch css in right order */
  display: none;
  fill: var(--color-grey-800);

  @media (min-width: $device-width-m) {
    .header-footer-algolia-search--open & {
      display: inline-block;
    }
  }
}

.header-footer-algolia-search-box__cancel-text {
  display: none;

  @media (max-width: $device-width-m-max) {
    .header-footer-algolia-search--open & {
      display: inline;
    }
  }
}

.header-footer-algolia-search-box__clear {
  display: none;
  padding: 4px;

  @media (max-width: $device-width-m-max) {
    margin-right: 12px;
  }

  .header-footer-algolia-search--open & {
    display: flex;
  }
}

.header-footer-algolia-search-box__clear-icon {
  display: none;
  fill: var(--color-grey-600);

  .header-footer-algolia-search--open & {
    display: inline-block;
  }
}
