$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-footer-algolia-result-link {
  display: flex;
  justify-content: center;
  padding: 24px 0 12px;

  @media (min-width: $device-width-s) {
    flex: 1;
    align-items: flex-end;
    padding: 24px 0;
    width: 100%;
  }
}

.header-footer-algolia-result-link__no-results {
  color: var(--color-grey-400);
  justify-content: center;
  padding-bottom: 40px;

  @media (min-width: $device-width-s) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: $device-width-s-max) {
  .header-footer-algolia-result-link__no-results:not(.header-footer-algolia-result-link__no-results--selected) {
    display: none;
  }

  .header-footer-algolia-result-link__no-results--selected {
    align-items: center;
    background: --color-white;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content: center;
    padding-bottom: 50vh;
  }
}

.header-footer-algolia-result-link__no-results-link {
  margin-top: 4px;
  text-align: center;
}
