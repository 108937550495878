$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-footer-algolia-search {
  position: relative;
}

@media (max-width: $device-width-m-max) {
  .header-footer-algolia-search--open {
    bottom: 0;
    left: 0;
    max-height: 100%;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
  }
}

.header-footer-algolia-search__content {
  margin: 0 auto;

  @media (max-width: $device-width-m-max) {
    .header-footer-algolia-search--open & {
      height: 100vh;
    }
  }
}

// results: own file and correct bem?
.header-footer-algolia-result {
  display: none;
  justify-content: space-between;
  padding-top: 45px; // height of sticky-stuff

  @media (max-width: $device-width-m-max) {
    flex-direction: column;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }

  .header-footer-algolia-search--open & {
    display: flex;
  }
}

.header-footer-algolia-result__row {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;

  @media (max-width: $device-width-m-max) {
    flex-direction: column;
  }

  @media (min-width: $device-width-m) {
    :not(.header-footer-algolia-result--2-columns) & {
      width: 50%;
    }
  }
}

@media (min-width: $device-width-m) and (max-width: $device-width-m-max) {
  .header-footer-algolia-result__row:last-of-type {
    margin-top: 60px;
  }
}

@media (min-width: $device-width-m) {
  .header-footer-algolia-result:not(.header-footer-algolia-result--2-columns) .header-footer-algolia-result__row:first-of-type {
    border-right: 1px solid var(--color-grey-100);
  }
}

@media (min-width: $device-width-m) {
  .header-footer-algolia-result--3-columns .header-footer-algolia-result__row:first-of-type {
    width: 66%;
  }

  .header-footer-algolia-result--3-columns .header-footer-algolia-result__row:last-of-type {
    width: 33%;
  }
}

.header-footer-algolia-result__row-column {
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0;

  @media (min-width: $device-width-m) {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 0 40px;
    position: relative;
    width: 50%;
  }
}

@media (max-width: $device-width-m-max) {
  .header-footer-algolia-result__row:last-of-type .header-footer-algolia-result__row-column:not(.header-footer-algolia-result__row-column--colored-background):last-of-type {
    padding-bottom: 124px;
  }

  .header-footer-algolia-result__row-column--faq {
    padding-bottom: 124px;
    padding-top: 0;
  }
}

.header-footer-algolia-result__row-column--colored-background {
  background-color: var(--color-sand-100);
  min-height: 100vh;
  padding: 0 0 124px;
}

.header-footer-algolia-result__row-column--hidden {
  display: none;
}

@media (min-width: $device-width-m) {
  .header-footer-algolia-result__row-column:not(:last-of-type) {
    border-right: 1px solid var(--color-grey-100);
  }
}

.header-footer-algolia-result__row-column-label {
  display: block;
  font-family: var(--font-family-headline);
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 24px;
  text-align: left;
}

@media (max-width: $device-width-m-max) {
  .header-footer-algolia-result__row-column-label--no-results {
    margin-bottom: 0;
  }
}

.header-footer-algolia-result__row-column-label-count {
  font-family: var(--font-family-headline);

  &::before {
    content: ' ';
  }
}

@media (min-width: $device-width-m) {
  .header-footer-algolia-result__row-column-data {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
}
